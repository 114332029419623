<template>
  <div class="position-relative" v-bind:class="{ 'open': openSuggestion }">
    <input
        class="form-control"
        type="text"
        v-model="selection"
        @focus="focus"
        @keydown.enter="enter"
        @keydown.down="down"
        @keydown.up="up"
        @input="change"
        :placeholder="placeholder"
    />
    <b-icon class="icon-x-autocomplete" icon="x" font-scale="1.5" v-on:click="cancelResearch"></b-icon>
    <div
        class="position-absolute w-100"
        v-if="openSuggestion"
    >
      <div class="card card-search-autocomplete">
        <div class="d-flex justify-content-between">
            <span class="pt-1 pl-1 pb-2">
              Résultat(s) de la recherche :
              <b>{{ matches.length }}</b> {{ researchLabel }}
            </span>
        </div>
        <hr class="p-0 m-0">
        <div class="card-body p-0">
          <div class="" v-for="(suggestion, index) in matches" :key="index">
            <div class="autocomplete-result c-pointer w-100 p-2" @click="suggestionClick($event, index)">
              {{ suggestion.name }} | {{ (entity === 'shop') ? suggestion.center.name : '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      selection: '',
      selectedSuggestions: []
    }
  },
  props: {
    suggestions: {
      type: Array,
      required: true
    },
    listId: {
      type: Array,
      required: false
    },
    researchLabel: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    }
  },
  watch: {
    selection(newValue) {
      this.selection = newValue
    }
  },
  methods: {
    // When focus on the input
    focus() {

    },

    // When enter pressed on the input
    enter() {
      this.selection = this.matches[this.current];
      this.isOpen = false;
    },

    // When up pressed while suggestions are open
    up() {
      if(this.current > 0)
        this.current--
    },

    // When up pressed while suggestions are open
    down() {
      if(this.current < this.suggestions.length - 1)
        this.current++
    },

    // For highlighting element
    isActive(index) {
      return index === this.current
    },

    // When the user changes input
    change() {
      if (this.isOpen === false) {
        this.isOpen = true
        this.current = 0
      }
    },

    cancelResearch() {
      this.selection = ''
    },

    // When one of the suggestion is clicked
    suggestionClick(event, index) {
      let model = {
        checked: event,
        result: this.matches[index],
        entity: this.entity
      }

      this.isOpen = false
      this.selection = ''

      this.$emit('click-suggestion', model)
    },
  },
  computed: {
    // Filtering the suggestion based on the input
    matches() {
      return this.suggestions.filter((obj) => {
        return obj.name.toLowerCase().indexOf(this.selection.toLowerCase()) >= 0
      })
    },
    // The flag
    openSuggestion() {
      return this.selection !== ""
          && this.matches.length !== 0
          && this.isOpen === true
    }
  }
}
</script>
